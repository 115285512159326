import { OrderError } from "@containers/OrderError";
import { ReactElement } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { SupportMessage } from "@components/SupportMessage";
import { useBrand } from "@hooks/useBrand";

const NotFound = (): ReactElement => {
  const { t } = useTranslation(["page-error"]);
  const { brand } = useBrand();

  return (
    <OrderError title={t("pageNotFound.title", { context: brand })}>
      <>
        {t("pageNotFound.message", { context: brand })}{" "}
        <SupportMessage subject={t("pageNotFound.subject", { context: brand })} />
      </>
    </OrderError>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale || process.env.NEXT_PUBLIC_DEFAULT_LANG || "en")),
  },
});

export default NotFound;
